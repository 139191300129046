<template>
  <div style="height: 100%" class="bulk-upload">
    <div class="reports-parent" style="height: 100%" ref="scrollable" v-bar>
      <div>
        <v-container class="lighten-5">
          <v-row v-if="bUploadInProgress != null">
            <v-col cols="12" sm="12" md="12" lg="12" class="px-5 my-10">
              <v-card class="mx-auto pa-3" tile>
                <v-row no-gutters>
                  Processing {{ bUploadInProgress }} data in progress, please
                  <a
                    class="retry-library-data"
                    @click.prevent="getLibraryData('Loading')"
                    >try again</a
                  >
                  after some time.</v-row
                >
              </v-card>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="px-5 my-10 bulk-upload-widget"
            >
              <v-card class="mx-auto pa-3" tile>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="text-right">
                    <v-card-text class="bulk-upload-title pa-0"
                      >Books Data</v-card-text
                    >
                    <v-btn
                      :disabled="clearBookDataExport === null"
                      @click="
                        clearBookDataExport ? clearBookDataExport() : () => {}
                      "
                      x-small
                      tile
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                    <v-btn
                      :disabled="startBookDataExport === null"
                      @click="
                        startBookDataExport ? startBookDataExport() : () => {}
                      "
                      x-small
                      tile
                      color="primary"
                      class="ml-2 mb-2"
                      >Start upload</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    :class="[
                      bookDataFile ? 'has-file' : '',
                      'export-dropzone-wrapper'
                    ]"
                  >
                    <vue-dropzone
                      ref="bookBulkUploadDropZone"
                      id="dropzone"
                      :options="booksExportOptions"
                    ></vue-dropzone>
                  </v-col>
                  <v-col cols="12" sm="12" class="mt-2">
                    <v-btn
                      x-small
                      tile
                      class="primary mr-2"
                      @click="undoUpload()"
                    >
                      Undo Last Bulk Upload
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      class="primary"
                      @click="undoUpload('last_hour')"
                    >
                      Undo All Bulk Upload in last hour
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card-text class="px-0"
                      >Please note: You can only use xlsx file for bulk upload,
                      dates should be in the format dd-mm-yyyy and xlsx file
                      should contain these mandatory column titles, entires
                      without these mantatory fields are omitted,
                      <ul class="py-3">
                        <li>Book Name</li>
                        <li>Author Name</li>
                        <li>Category</li>
                        <li>Date added</li>
                        <li>Price</li>
                      </ul>
                      <a :href="xlsxBooksSampleFileUrl">Click here</a> to
                      download a sample xlsx file for reference.
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
              lg="6"
              class="px-5 my-10 bulk-upload-widget"
            >
              <v-card class="mx-auto pa-3" tile>
                <v-row no-gutters>
                  <v-col cols="12" sm="12" class="text-right">
                    <v-card-text class="bulk-upload-title pa-0"
                      >Members Data</v-card-text
                    >
                    <v-btn
                      :disabled="clearMemberDataExport === null"
                      @click="
                        clearMemberDataExport
                          ? clearMemberDataExport()
                          : () => {}
                      "
                      x-small
                      tile
                      class="ml-2 mb-2"
                      >Clear</v-btn
                    >
                    <v-btn
                      :disabled="startMemberDataExport === null"
                      @click="
                        startMemberDataExport
                          ? startMemberDataExport()
                          : () => {}
                      "
                      x-small
                      tile
                      color="primary"
                      class="ml-2 mb-2"
                      >Start upload</v-btn
                    >
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    :class="[
                      memberDataFile ? 'has-file' : '',
                      'export-dropzone-wrapper'
                    ]"
                  >
                    <vue-dropzone
                      ref="memberBulkUploadDropZone"
                      id="dropzone"
                      :options="membersExportOptions"
                    ></vue-dropzone>
                  </v-col>
                  <v-col cols="12" sm="12" class="mt-2">
                    <v-btn
                      x-small
                      tile
                      class="primary mr-2"
                      @click="undoUpload('last_upload', 'members')"
                    >
                      Undo Last Bulk Upload
                    </v-btn>
                    <v-btn
                      x-small
                      tile
                      class="primary"
                      @click="undoUpload('last_hour', 'members')"
                    >
                      Undo All Bulk Upload in last hour
                    </v-btn>
                  </v-col>
                  <v-col cols="12" sm="12">
                    <v-card-text class="px-0"
                      >Please note: You can only use xlsx file for bulk upload,
                      dates should be in the format dd-mm-yyyy and xlsx file
                      should contain these mandatory column titles, entires
                      without these mantatory fields are omitted,
                      <ul class="py-3">
                        <li>Member Name</li>
                        <li>Address</li>
                        <li>Gender</li>
                        <li>Date of join</li>
                        <li>Membership Class</li>
                      </ul>
                      <a :href="xlsxMembersSampleFileUrl">Click here</a> to
                      download a sample xlsx file for reference.
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { useBulkUpload } from "@/modules";

export default {
  components: {
    vueDropzone: vue2Dropzone
  },
  setup: useBulkUpload
};
</script>
